<template>
  <div>
    <div
      class="container-top"
      :class="{ 'container-medium': lgAndUp || xlAndUp }"
    >
      <div class="row d-md-flex justify-content-between mt-3 mb-5">
        <div class="col-md-12">
          <div class="text-head h2 mt-3 font-weight-bold">
            New Guest Life Cycle Settings
          </div>
          <div @click="goBack">
            <span class="s-18 fw-400 cursor-pointer text-black">
              <img src="../../assets/gobacck.png" class="w-12 h-12" alt="" /> Go
              back</span
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row border-radius-8 bg-gray-200 pb-3 pt-3 px-3 mt-5">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="mt-2 text-dak fw-500 mb-2">
                    Add Your New Guest Life Cycle
                  </h4>
                </div>
                <Toast />
                <ConfirmDialog />
              </div>

              <div class="row">
                <div class="col-md-12 py-2">
                  <div class="row d-flex">
                    <div class="col-md-12">
                      <el-input
                        type="text"
                        class="w-100"
                        placeholder="Add Your New Guest Life Cycle"
                        v-model="firstTimerTypes"
                        size="large"
                      />
                    </div>
                  </div>
                  <div class="row mt-2 d-flex">
                    <div class="col-md-6 d-flex flex-wrap">
                      <div class="col-md-6">
                        <div>
                          <el-checkbox v-model="isDefault" :binary="true" />
                          <span class="ml-2 mt-2">Mark As default</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <el-checkbox v-model="createGroup" :binary="true" />
                          <span class="ml-2 mt-2"
                            >Create group
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="This creates a group where members in this lifecycle will be added"
                              placement="top-start"
                            >
                            </el-tooltip>
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-3"></div> -->
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="col-md-3">
                        <el-button
                          round
                          size="large"
                          :color="primarycolor"
                          class="font-weight-bold primary-btn w-100 text-white py-4 mt-3"
                          @click="saveFirstTimer"
                          >Save</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <span>You can re-arrange your New Guest Life Cycle by dragging to the position you desired </span> -->

          <div class="row">
            <div
              class="col-md-12 border-radius-8 mt-4"
              style="border: 1px solid #aaaaaa"
            >
              <div class="row table-header-row py-3">
                <div class="col-md-7">
                  <span class="py-2 pl-3 fw-500 text-dak">Life Cycle</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center p-5" v-if="loading">
                  <i
                    class="pi pi-spin pi-spinner text-center text-primary"
                    style="font-size: 3rem"
                  ></i>
                </div>
              </div>
              <div
                class="list-group-item list-group row"
                v-for="(firstTimer, indx) in firstTimerData"
                :key="indx"
                @drop="handleDrop"
              >
                <div class="col-md-12">
                  <div class="row pl-2">
                    <div
                      class="col-md-7 px-0 d-flex justify-content-between align-items-center mb-md-0 mb-5"
                    >
                      <span class="py-2 text-dak fw-500  hidden-header">Name</span>

                      <span class="py-2 font-weight-bold text-dak text-xs-left mr-md-0  mr-4">{{
                        firstTimer.name
                      }}</span>
                    </div>

                    <div
                      class="col-md-5 px-0 mb-md-0 mb-2 col-12 d-flex justify-content-end align-items-end"
                    >
                      <!-- <span class="py-md-4 hidden-header hidden-header1"
                        >ACTION</span
                      > -->
                      <div class="row" v-if="vissibleTab !== `tab_${firstTimer.index}`">
                        <div
                          class="col-md-6 col-6 d-flex justify-content-center"
                        >
                          <el-button
                            class="py-1 px-4"
                            style="background-color: #b4f1f4"
                            @click="openClassification(firstTimer.index)"
                            size="large"
                            round
                            color="#EBEFF4"
                          >
                            <span style="color: #030303 !important" class="fw-400 s-16"
                              >Edit</span
                            >
                          </el-button>
                        </div>
                        <div
                          class="col-md-6 col-6 d-flex justify-content-start"
                        >
                          <el-button
                            class="py-1 delete-btn text-white"
                            @click="deletePop(firstTimer.id)"
                            size="large"
                            round
                          >
                            <span class="s-16 fw-400">Delete</span>
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row bg-gray-200 py-1 border-radius-8 ml-0  mr-1 mt-2"
                    v-if="vissibleTab === `tab_${firstTimer.index}`"
                  >
                    <div
                      class="col-md-7 d-flex justify-content-between align-items-center mb-md-0 mb-2"
                    >
                      <label for="" class="d-flex mt-4">
                        <!-- <span class="mr-2">Name</span> -->
                        <el-input
                          type="text"
                          class="w-100"
                          v-model="firstTimer.name"
                        />
                      </label>
                      <label for="" class="d-flex mt-4">
                        <span class="mr-2">Mark As Default</span>
                        <el-checkbox
                          v-model="firstTimer.isDefault"
                          :binary="true"
                        />
                      </label>
                    </div>
                    <div
                      class="col-md-5 d-flex justify-content-end align-items-center mt-0"
                    >
                      <div class="row">
                        
                        <div
                          class="col-md-6 col-6 d-flex justify-content-start"
                        >
                        <el-button
                            class="secondary-button px-3"
                            @click="discard"
                            color="#F6F6F6"
                            round
                          >
                            Discard
                          </el-button>
                          
                        </div>
                        <div class="col-md-6 col-6 d-flex justify-content-start">
                          <el-button
                            round
                            class="text-white py-1 px-4 ml-md-0 ml-5"
                            color="#0B55D4"
                            @click="
                              updateFirstTimer(firstTimer, firstTimer.index)
                            "
                            >Save</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/gateway/backendapi";
import { ElMessage, ElMessageBox } from "element-plus";
import Checkbox from "primevue/checkbox";
import membershipService from "../../services/membership/membershipservice";
import finish from "../../services/progressbar/progress";
import Tooltip from "primevue/tooltip";
import router from "../../router";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";

export default {
  name: "simple",
  display: "Simple",
  components: {
    Checkbox,
  },
  directives: {
    tooltip: Tooltip,
  },
  inject: ["primarycolor"],
  setup() {
    const { mdAndUp, lgAndUp, xlAndUp, xsOnly } = deviceBreakpoint();
    return {
      mdAndUp,
      lgAndUp,
      xlAndUp,
      xsOnly,
    };
  },
  data() {
    return {
      firstTimerData: [],
      vissibleTab: "",
      firstTimerName: "",
      firstTimerOrder: "",
      firstTimerTypes: "",
      isDefault: false,
      loading: false,
      enabled: true,
      dragging: false,
      createGroup: false,
    };
  },

  methods: {
    goBack() {
      router.go(-1);
    },
    async getFirstTimerCyles() {
      try {
        this.loading = true;
        const { data } = await axios.get("/firstimercycle");
        this.firstTimerData = data.returnObject
          .map((item, index) => {
            item.index = index;
            return item;
          })
          .sort((a, b) => a.order - b.order);
        console.log(data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    //handleDrop
    async handleDrop() {
      try {
        setTimeout(async () => {
          const ordered = this.firstTimerData.map((i, j) => {
            return { id: i.id, order: j + 1, name: i.name };
          });
          this.loading = true;
          const response = await axios.put(
            "/firsttimercycle/orderstages",
            ordered
          );
          console.log(response);
          this.loading = false;
          ElMessage({
            type: "success",
            message: "New Guest Life Cycle Order Updated Successfully",
            duration: 5000,
          });
          console.log(ordered, "ORDERED");
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },

    //First Timer save
    async saveFirstTimer() {
      try {
        let createFirsttimer = {
          name: this.firstTimerTypes,
          isDefault: this.isDefault,
          createAsAGroup: this.createGroup,
        };
        await axios.post("/firsttimercycle/create", createFirsttimer);
        this.getFirstTimerCyles();
        this.firstTimerTypes = "";
        this.isDefault = false;
        this.createGroup = false;
        ElMessage({
          type: "success",
          message: "New Guest Life Cycle Save Successfully",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },
    //Update FirstTimer
    async updateFirstTimer(item) {
      try {
        console.log(item, "item");
        await axios.put(`/firsttimercycle/${item.id}/edit`, {
          ...item,
          name: item.name,
          isDefault: item.isDefault,
        });
        this.discard();
        ElMessage({
          type: "success",
          message: "New Guest Life Cycle Updated Successfully",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },
    //Delete FirstTimers
    async deleteFirstTimer(id) {
      console.log(id);
      try {
        let data = await axios.delete(`/firsttimercycle/${id}/delete`);
        console.log(data);
        this.firstTimerData = this.firstTimerData.filter((i) => i.id !== id);
        ElMessage({
          type: "success",
          message: "Delete Successfully",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },
    //pop Alert
    deletePop(id) {
      ElMessageBox.confirm(
        "Are you sure you want to Delete?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          this.deleteFirstTimer(id);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    },

    openClassification(index) {
      this.vissibleTab = `tab_${index}`;
      this.firstTimerName = this.firstTimerData[index].name;
    },

    discard() {
      this.vissibleTab = "";
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
  },

  created() {
    this.getFirstTimerCyles();
  },
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #cbd1d3;
}
.list-group {
  min-height: 20px;
}
/* .list-group-item {
  cursor: move;
} */
.list-group-item i {
  cursor: pointer;
}
input::placeholder {
  text-align: center;
}
.delete-btn {
  background: #ff3737 !important;
}
.delete-btn:hover {
  background-color: red !important;
  color: white !important;
}
.table-header-row {
  background: #f6f6f6;
  border-radius: 8px;
}

.hidden-header {
  display: none;
}

.grey-background {
  background: #dde2e6;
}

.save-btn {
  background: #50ab00;
}
.first {
  font: var(--unnamed-font-style-normal) normal 800 34px/46px
    var(--unnamed-font-family-nunito-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 800 34px/46px Nunito Sans;
  letter-spacing: 0px;
  color: #02172e;
  opacity: 1;
}
.first1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    24px/32px var(--unnamed-font-family-nunito-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 24px/32px Nunito Sans;
  letter-spacing: 0px;
  color: #02172e;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .hidden-header1 {
    display: none !important;
  }
  .hidden-header {
    display: inline-block;
    font-weight: bold;
  }

  .table-header-row {
    display: none;
  }
}
@media screen and (max-width: 390px) {
  .first {
    font: var(--unnamed-font-style-normal) normal 500 20px/25px
      var(--unnamed-font-family-nunito-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 800 20px/25px Nunito Sans;
    letter-spacing: 0px;
    color: #02172e;
    opacity: 1;
  }
  .first1 {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-bold) 24px/32px
      var(--unnamed-font-family-nunito-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 15px/16px Nunito Sans;
    letter-spacing: 0px;
    color: #02172e;
    opacity: 1;
  }
}
.help {
  border: 1px solid #6a6a6a;
  width: 14px;
  height: 14px;
  display: inline-block;
  padding: 6px 0px 6px 3px;
  border-radius: 50%;
  color: #6a6a6a;
  cursor: pointer;
}
</style>
